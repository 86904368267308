.mdc-tooltip__surface {
  border-radius: 4px;
  /* @alternate */
  border-radius: var(--mdc-shape-small, 4px);
}

.mdc-tooltip__surface {
  color: white;
  /* @alternate */
  color: var(--mdc-theme-text-primary-on-dark, white);
}

.mdc-tooltip__surface {
  background-color: rgba(0, 0, 0, 0.6);
}

.mdc-tooltip__surface {
  word-break: break-all;
  /* @alternate */
  word-break: var(--mdc-tooltip-word-break, normal);
  overflow-wrap: anywhere;
}

.mdc-tooltip {
  z-index: 2;
}

.mdc-tooltip--showing-transition .mdc-tooltip__surface {
  transition: opacity 150ms 0ms cubic-bezier(0, 0, 0.2, 1), transform 150ms 0ms cubic-bezier(0, 0, 0.2, 1);
}

.mdc-tooltip--hide-transition .mdc-tooltip__surface {
  transition: opacity 75ms 0ms cubic-bezier(0.4, 0, 1, 1);
}

.mdc-tooltip__title {
  color: rgba(0, 0, 0, 0.87);
  /* @alternate */
  color: var(--mdc-theme-text-primary-on-light, rgba(0, 0, 0, 0.87));
}

.mdc-tooltip__content {
  color: rgba(0, 0, 0, 0.6);
}

.mdc-tooltip__content-link {
  color: #6200ee;
  /* @alternate */
  color: var(--mdc-theme-primary, #6200ee);
}

.mdc-tooltip {
  position: fixed;
  display: none;
}

.mdc-tooltip-wrapper--rich {
  position: relative;
}

.mdc-tooltip--shown,
.mdc-tooltip--showing,
.mdc-tooltip--hide {
  display: inline-flex;
}
.mdc-tooltip--shown.mdc-tooltip--rich,
.mdc-tooltip--showing.mdc-tooltip--rich,
.mdc-tooltip--hide.mdc-tooltip--rich {
  /* @alternate */
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  display: inline-block;
  border-radius: 8px;
  left: -320px;
  padding: 8px 8px;
  position: absolute;
}
.mdc-tooltip--shown.mdc-tooltip--rich .mdc-tooltip__surface,
.mdc-tooltip--showing.mdc-tooltip--rich .mdc-tooltip__surface,
.mdc-tooltip--hide.mdc-tooltip--rich .mdc-tooltip__surface {
  background-color: rgba(255, 255, 255, 0.6);
}

.mdc-tooltip__surface {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-caption-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
  font-size: 0.75rem;
  /* @alternate */
  font-size: var(--mdc-typography-caption-font-size, 0.75rem);
  font-weight: 400;
  /* @alternate */
  font-weight: var(--mdc-typography-caption-font-weight, 400);
  letter-spacing: 0.0333333333em;
  /* @alternate */
  letter-spacing: var(--mdc-typography-caption-letter-spacing, 0.0333333333em);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-caption-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-caption-text-transform, inherit);
  line-height: 16px;
  padding: 4px 8px;
  min-width: 40px;
  max-width: 200px;
  min-height: 24px;
  max-height: 40vh;
  box-sizing: border-box;
  overflow: hidden;
  text-align: center;
  transform: scale(0.8);
  opacity: 0;
  will-change: transform, opacity;
  outline: 1px solid transparent;
}
.mdc-tooltip--rich .mdc-tooltip__surface {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  min-height: 24px;
  min-width: 40px;
  max-width: 320px;
}
.mdc-tooltip--multiline .mdc-tooltip__surface {
  /* @noflip */
  text-align: left;
}
[dir=rtl] .mdc-tooltip--multiline .mdc-tooltip__surface, .mdc-tooltip--multiline .mdc-tooltip__surface[dir=rtl] {
  /* @noflip */
  text-align: right;
}
.mdc-tooltip--shown .mdc-tooltip__surface {
  transform: scale(1);
  opacity: 1;
}
.mdc-tooltip--hide .mdc-tooltip__surface {
  transform: scale(1);
}
.mdc-tooltip__surface .mdc-tooltip__title {
  display: block;
  margin-top: 0;
  /* @alternate */
  line-height: normal;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-subtitle2-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
  font-size: 0.875rem;
  /* @alternate */
  font-size: var(--mdc-typography-subtitle2-font-size, 0.875rem);
  font-weight: 500;
  /* @alternate */
  font-weight: var(--mdc-typography-subtitle2-font-weight, 500);
  letter-spacing: 0.0071428571em;
  /* @alternate */
  letter-spacing: var(--mdc-typography-subtitle2-letter-spacing, 0.0071428571em);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-subtitle2-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-subtitle2-text-transform, inherit);
  margin: 0 8px;
}
.mdc-tooltip__surface .mdc-tooltip__title::before {
  display: inline-block;
  width: 0;
  height: 20px;
  content: "";
  vertical-align: 0;
}
.mdc-tooltip__surface .mdc-tooltip__content {
  display: block;
  margin-top: 0;
  /* @alternate */
  line-height: normal;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-body1-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
  font-size: 1rem;
  /* @alternate */
  font-size: var(--mdc-typography-body1-font-size, 1rem);
  font-weight: 400;
  /* @alternate */
  font-weight: var(--mdc-typography-body1-font-weight, 400);
  letter-spacing: 0.03125em;
  /* @alternate */
  letter-spacing: var(--mdc-typography-body1-letter-spacing, 0.03125em);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-body1-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-body1-text-transform, inherit);
  margin: 0 8px 16px 8px;
  /* @noflip */
  text-align: left;
}
.mdc-tooltip__surface .mdc-tooltip__content::before {
  display: inline-block;
  width: 0;
  height: 24px;
  content: "";
  vertical-align: 0;
}
[dir=rtl] .mdc-tooltip__surface .mdc-tooltip__content, .mdc-tooltip__surface .mdc-tooltip__content[dir=rtl] {
  /* @noflip */
  text-align: right;
}
.mdc-tooltip__surface .mdc-tooltip__content-link {
  text-decoration: none;
}